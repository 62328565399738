
import { mapGetters } from "vuex";

export default {
  name: "AdModal",
  data() {
    return {
      adModal: false,
    };
  },
  computed: {
    ...mapGetters(["isDev"]),
  },
  mounted() {
    window.adsbygoogle ? (adsbygoogle = window.adsbygoogle || []).push({}) : "";
    setTimeout(() => {
      if (window.adsbygoogle) {
        (adsbygoogle = window.adsbygoogle || []).push({});
      } else {
        this.adModal = this.isDev ? false : true;
      }
    }, 4000);
  },
  methods: {
    hideAds() {
      this.adModal = false;
    },
  },
};
