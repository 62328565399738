
import { mapGetters } from "vuex";
export default {
  name: "Sidebar",
  props: { isOpen: Boolean },
  data() {
    return { activeTab: "" };
  },
  computed: {
    ...mapGetters(["manager", "admin", "owner", "user", "superAdmin"]),
    roleName() {
      if (this.superAdmin) {
        return "Super Admin";
      } else if (this.admin) {
        return "Admin";
      } else if (this.manager) {
        return "Manager";
      } else if (this.owner) {
        return "Owner";
      } else if (this.user) {
        return "User";
      }
    },
    navLink() {
      const nav = [
        {
          show: this.manager || this.owner || this.superAdmin,
          name: "dashboard",
          title: "Dashboard",
          icon: "fas fa-cubes",
        },
        {
          show: true,
          name: "dashboard-blog",
          title: "Blog",
          icon: "fas fa-cubes",
        },
        {
          show: this.manager || this.owner,
          name: "dashboard-booking",
          title: "Booking",
          icon: "fas fa-clipboard-check",
        },
        {
          show: this.superAdmin,
          name: "dashboard-user",
          title: "User",
          icon: "fas fa-user",
        },
        {
          show: this.superAdmin,
          name: "dashboard-location",
          title: "Location",
          icon: "fas fa-location-dot",
        },
        {
          show: this.superAdmin,
          name: "dashboard-accommodation-type",
          title: "Accommodation",
          icon: "fas fa-people-roof",
        },
        {
          show: this.superAdmin,
          name: "dashboard-hotel",
          title: "Hotel",
          icon: "fas fa-hotel",
        },
        {
          show: !this.manager,
          type: "dropdown",
          name: "dashboard-manage",
          title: "manage hotel",
          icon: "fas fa-hotel",
          subMenus: [
            {
              show: this.superAdmin,
              name: "dashboard-manage-facility",
              title: "Facility",
            },
            {
              show: this.owner,
              name: "dashboard-manage-complement",
              title: "Complement",
            },
            {
              show: this.superAdmin,
              name: "dashboard-manage-bed-type",
              title: "Bed Type",
            },
            {
              show: this.owner,
              name: "dashboard-manage-extra-service",
              title: "Extra Service",
            },
            {
              show: this.owner,
              name: "dashboard-manage-room-type",
              title: "Room Type",
            },
            {
              show: this.admin,
              name: "dashboard-manage-approve-room-type",
              title: "Approve Room Type",
            },
            {
              show: this.owner,
              name: "dashboard-manage-room",
              title: "Room",
            },
          ],
        },
        {
          show: this.manager || this.admin || this.owner,
          name: "dashboard-settings",
          title: "Settings",
          icon: "fas fa-gear",
        },
      ];
      return nav;
    },
  },
  mounted() {
    this.setActiveTab(this.$route.name.split("-")[0] || "");
  },
  methods: {
    check(name) {
      return this.$route.name === name
        ? "bg-indigo-600 text-white"
        : "text-gray-700";
    },
    async logOut() {
      try {
        await this.$auth.logout("laravelJWT");
        this.$router.push({ name: "dashboard-login" });
      } catch (error) {}
    },
    setActiveTab(name) {
      this.activeTab = this.activeTab === name ? "" : name;
    },
  },
};
